import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  products = [
    {
      label: "City of Toronto: Building Public Portal",
      url: "http://app.toronto.ca/ApplicationStatus/setup.do?action=init",
      imageSrc: "assets/products/CoT_PublicSearch.JPG",
      desc: "Allow the Public to search for all building permits in the City of Toronto, viewing results on a map via ESRI."
      //  Created a Public Portal for City of Toronto Building users, allowing them to register, login, view, apply, pay, for permits and licenses, and to schedule inspections."
    },
    {
      label: "Shipment Live Tracking, Analytics, and Reports",
      desc: "Increased package deliveries by 5000 in two weeks by implementing live tracking, analytics, and reports.",
      url: "https://shipper.shipperbee.com/track",
      imageSrc: "assets/products/shipperbee-track.JPG"
    },
    {
      label: "City of Guelph Public Search and Web Portal",
      desc: "Allow the Public to search for all building permits in the City of Guelph. Can also Login, Apply for Permits, and pay online.",
      url: "https://gpas.guelph.ca/Guelph/sfjsp?interviewID=SignOn",
      imageSrc: "assets/products/Guelph_PublicSearch.JPG"
    },
    {
      label: "ShipperBee: Route Simulation and Optimization",
      url: "http://shipperbee.com",
      imageSrc: "assets/products/last-mile.jpg",
      desc: "Optimized Delivery Routes to save 75% in CO₂ emissions simulating results across Ontario."
    },

    
  ]
  constructor() { }

  ngOnInit() {
  }

}
