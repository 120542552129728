import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss']
})
export class SkillsComponent implements OnInit {
  DSsrc = 'assets/skills/info-graphic.png'
  SAsrc= 'assets/skills/matrix-code.jpg'
  BAsrc = 'assets/skills/teamwork_props.jpg'
  skills = [
    {
      imageSrc: this.BAsrc,
      label:"Consultant / Analyst"
    },
    {
      imageSrc: this.SAsrc,
      label:"Software Architect"
    },
    {
      imageSrc: this.DSsrc,
      label:"Data Scientist"
    },
  ]
  constructor() { }

  ngOnInit() {
  }

}
