import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-poweredby',
  templateUrl: './footer-poweredby.component.html',
  styleUrls: ['./footer-poweredby.component.scss']
})
export class FooterPoweredbyComponent implements OnInit {

  baseUrl = 'assets/poweredBy/'
  logos = [
    {
      alt: 'Microsoft Azure',
      src: this.baseUrl + 'Microsoft_Azure_Logo.png'
    },
    {
      alt: 'Azure App Service',
      src: this.baseUrl + 'azure_app_service_logo.png'
    },
    {
      alt: 'Azure Dev Ops 3',
      src: this.baseUrl + 'Azure-DevOps-3.png'
    },
    {
      alt: 'Angular 3',
      src: this.baseUrl + 'Angular-7-logo.png'
    }
  ]
  constructor() {
   
  }


  ngOnInit() {

  }

}
