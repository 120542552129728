import {NgModule} from '@angular/core';
import {MdcButtonModule} from '@angular-mdc/web/button';

@NgModule({
  exports: [

    MdcButtonModule,

  ]
})
export class MaterialModule {}